import {
    URL_AUTH,
    URL_BASE,
    URL_OPPORTUNITIES,
    URL_HOURLY_OPTIONS,
    URL_FIRST_ARRIVALS,
    URL_CONTRACTED_POWER,
    URL_SIMULATIONS,
    URL_EQUIPMENT,
    URL_EQUIPMENT_UTILIZATION_FREQUENCY,
    URL_EQUIPMENT_SEASON,
    URL_EQUIPMENT_HOURLY_PERIOD,
    URL_EQUIPMENT_HOURLY_PERIOD_MEAL,
    URL_WEEKEND_IN_HOUSE,
    URL_WORK_DAYS_OCCUPATION_PERIOD,
    URL_SIMULATION_STATUS,
    URL_PRODUCT,
    URL_CLIENT,
    URL_USER,
    URL_SALES_TEAM,
    URL_CHANNELS,
    URL_SUPERVISORS,
    URL_PARTNERS,
    URL_SALES_POINT,
    URL_IMG_CONTRACT,
    URL_LEADS,
    URL_LOGOUT,
    URL_USER_TYPE,
    URL_USER_GROUP,
    URL_USER_ACCOUNT,
    URL_USER_MANAGEMENT_SALES_POINT,
    URL_USER_MANAGEMENT_PARTNERS,
    URL_EV_MODELS,
    URL_ELECTRIC_INSTALLATION_TYPE,
    URL_INSTALLATION_CONNECTION,
    URL_CPES,
    URL_DISTANCE_PARKING_SPOTS,
    URL_DISTANCE_PARKING_SPOTS_QP,
    URL_USER_DETAILS,
    URL_DISCOUNT,
    URL_OLD_EQUIPMENT_TYPE,
    URL_ENERGY_SOURCE,
    URL_HOUSE_TYPE,
    URL_NUM_OCCUPANTS,
    URL_KITCHEN_EQUIPMENT,
    URL_KITCHEN_EQUIPMENT_ENERGY_SOURCE,
    URL_EQUIPMENT_DAILY_PERIOD,
    URL_GAS_MONTHLY_INVOICE,
    URL_TANK_TYPE,
    URL_USAGE_PERIOD,
    URL_DISTRICT_CONDITIONS,
    URL_ROOM_TYPE,
    URL_ELECTRICAL_HOUSE,
    URL_BOX_CONNECTION_TYPE,
    URL_BALCONY_LENGTH,
    URL_DISTANCE_RANGES,
    URL_DAILY_COOKING_TIME,
    URL_NIF,
    URL_ELECTRICAL_CHAIN,
    URL_BUSINESS_MODELS,
    URL_SPACE_TYPE,
    URL_AUTH_MODES,
    URL_REPORTING,
    URL_CHARGING_POWER,
    URL_INSTALLATION_SITE,
    URL_MAIN_ELECTRICAL_PANEL,
    URL_NETWORK_CONNECTION,
    URL_NETWORK_CONNECTION_TYPES,
    URL_PROPERTY_OWNER_TYPE
} from "constants/endpoints";
import { getBaseURL } from "./settings";



/**
 * getURL
 * 
 * @param {*} name 
 */
export function getEndpoint(name) {
    switch (name) {
        case URL_BASE:
            return getBaseURL();
        case URL_AUTH:
            return getBaseURL() + URL_AUTH;
        case URL_OPPORTUNITIES:
            return getBaseURL() + URL_OPPORTUNITIES;
        case URL_HOURLY_OPTIONS:
            return getBaseURL() + URL_HOURLY_OPTIONS;
        case URL_FIRST_ARRIVALS:
            return getBaseURL() + URL_FIRST_ARRIVALS;
        case URL_CONTRACTED_POWER:
            return getBaseURL() + URL_CONTRACTED_POWER;
        case URL_SIMULATIONS:
            return getBaseURL() + URL_SIMULATIONS;
        case URL_EQUIPMENT:
            return getBaseURL() + URL_EQUIPMENT;
        case URL_EQUIPMENT_UTILIZATION_FREQUENCY:
            return getBaseURL() + URL_EQUIPMENT_UTILIZATION_FREQUENCY;
        case URL_EQUIPMENT_SEASON:
            return getBaseURL() + URL_EQUIPMENT_SEASON;
        case URL_EQUIPMENT_HOURLY_PERIOD:
            return getBaseURL() + URL_EQUIPMENT_HOURLY_PERIOD;
        case URL_EQUIPMENT_HOURLY_PERIOD_MEAL:
            return getBaseURL() + URL_EQUIPMENT_HOURLY_PERIOD_MEAL;
        case URL_WEEKEND_IN_HOUSE:
            return getBaseURL() + URL_WEEKEND_IN_HOUSE;
        case URL_WORK_DAYS_OCCUPATION_PERIOD:
            return getBaseURL() + URL_WORK_DAYS_OCCUPATION_PERIOD;
        case URL_SIMULATION_STATUS:
            return getBaseURL() + URL_SIMULATION_STATUS;
        case URL_PRODUCT:
            return getBaseURL() + URL_PRODUCT;
        case URL_CLIENT:
            return getBaseURL() + URL_CLIENT;
        case URL_USER:
            return getBaseURL() + URL_USER;
        case URL_USER_ACCOUNT:
            return getBaseURL() + URL_USER_ACCOUNT;
        case URL_USER_DETAILS:
            return getBaseURL() + URL_USER_DETAILS;
        case URL_USER_TYPE:
            return getBaseURL() + URL_USER_TYPE;
        case URL_USER_GROUP:
            return getBaseURL() + URL_USER_GROUP;
        case URL_SALES_TEAM:
            return getBaseURL() + URL_SALES_TEAM;
        case URL_CHANNELS:
            return getBaseURL() + URL_CHANNELS;
        case URL_PARTNERS:
            return getBaseURL() + URL_PARTNERS;
        case URL_USER_MANAGEMENT_PARTNERS:
            return getBaseURL() + URL_USER_MANAGEMENT_PARTNERS;
        case URL_SUPERVISORS:
            return getBaseURL() + URL_SUPERVISORS;
        case URL_SALES_POINT:
            return getBaseURL() + URL_SALES_POINT;
        case URL_USER_MANAGEMENT_SALES_POINT:
            return getBaseURL() + URL_USER_MANAGEMENT_SALES_POINT;
        case URL_IMG_CONTRACT:
            return getBaseURL() + URL_IMG_CONTRACT;
        case URL_LEADS:
            return getBaseURL() + URL_LEADS;
        case URL_LOGOUT:
            return getBaseURL() + URL_LOGOUT;
        case URL_EV_MODELS:
            return getBaseURL() + URL_EV_MODELS;
        case URL_ELECTRIC_INSTALLATION_TYPE:
            return getBaseURL() + URL_ELECTRIC_INSTALLATION_TYPE;
        case URL_INSTALLATION_CONNECTION:
            return getBaseURL() + URL_INSTALLATION_CONNECTION;
        case URL_CPES:
            return getBaseURL() + URL_CPES;
        case URL_DISTANCE_PARKING_SPOTS:
            return getBaseURL() + URL_DISTANCE_PARKING_SPOTS;
        case URL_DISTANCE_PARKING_SPOTS_QP:
            return getBaseURL() + URL_DISTANCE_PARKING_SPOTS_QP;
        case URL_DISCOUNT:
            return getBaseURL() + URL_DISCOUNT;
        case URL_OLD_EQUIPMENT_TYPE:
            return getBaseURL() + URL_OLD_EQUIPMENT_TYPE;
        case URL_DAILY_COOKING_TIME:
            return getBaseURL() + URL_DAILY_COOKING_TIME;
        case URL_ENERGY_SOURCE:
            return getBaseURL() + URL_ENERGY_SOURCE;
        case URL_HOUSE_TYPE:
            return getBaseURL() + URL_HOUSE_TYPE;
        case URL_NUM_OCCUPANTS:
            return getBaseURL() + URL_NUM_OCCUPANTS;
        case URL_KITCHEN_EQUIPMENT:
            return getBaseURL() + URL_KITCHEN_EQUIPMENT;
        case URL_KITCHEN_EQUIPMENT_ENERGY_SOURCE:
            return getBaseURL() + URL_KITCHEN_EQUIPMENT_ENERGY_SOURCE;
        case URL_EQUIPMENT_DAILY_PERIOD:
            return getBaseURL() + URL_EQUIPMENT_DAILY_PERIOD;
        case URL_GAS_MONTHLY_INVOICE:
            return getBaseURL() + URL_GAS_MONTHLY_INVOICE;
        case URL_TANK_TYPE:
            return getBaseURL() + URL_TANK_TYPE;
        case URL_USAGE_PERIOD:
            return getBaseURL() + URL_USAGE_PERIOD;
        case URL_DISTRICT_CONDITIONS:
            return getBaseURL() + URL_DISTRICT_CONDITIONS;
        case URL_ROOM_TYPE:
            return getBaseURL() + URL_ROOM_TYPE;
        case URL_ELECTRICAL_HOUSE:
            return getBaseURL() + URL_SIMULATIONS + '/' +  URL_ELECTRICAL_HOUSE;
        case URL_DISTANCE_RANGES:
            return getBaseURL() + URL_DISTANCE_RANGES;
        case URL_BOX_CONNECTION_TYPE:
            return getBaseURL() + URL_BOX_CONNECTION_TYPE;
        case URL_BALCONY_LENGTH:
            return getBaseURL() + URL_BALCONY_LENGTH;
        case URL_NIF:
            return getBaseURL() + URL_NIF;
        case URL_ELECTRICAL_CHAIN:
            return getBaseURL() + URL_ELECTRICAL_CHAIN;
        case URL_BUSINESS_MODELS:
            return getBaseURL() + URL_BUSINESS_MODELS;
        case URL_SPACE_TYPE:
            return getBaseURL() + URL_SPACE_TYPE;
        case URL_AUTH_MODES:
            return getBaseURL() + URL_USER + '/' + URL_AUTH_MODES;
        case URL_REPORTING:
            return getBaseURL() + URL_REPORTING;
        case URL_CHARGING_POWER:
            return getBaseURL() + URL_CHARGING_POWER;
        case URL_INSTALLATION_SITE:
            return getBaseURL() + URL_INSTALLATION_SITE;
        case URL_MAIN_ELECTRICAL_PANEL:
            return getBaseURL() + URL_MAIN_ELECTRICAL_PANEL;
        case URL_NETWORK_CONNECTION:
            return getBaseURL() + URL_NETWORK_CONNECTION;
        case URL_NETWORK_CONNECTION_TYPES:
            return getBaseURL() + URL_NETWORK_CONNECTION_TYPES;
        case URL_PROPERTY_OWNER_TYPE:
            return getBaseURL() + URL_PROPERTY_OWNER_TYPE;
        default://URL_BASE
            return getBaseURL();
    }
}